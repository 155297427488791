import { graphql, useStaticQuery } from 'gatsby';
import HomeTrendUpIcon from 'svgs/single-case-study/home-trend-up.svg';
import NodeJsIcon from 'svgs/single-case-study/node-js.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

/* const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.questionOne`,
        answer: `${faqPath}.answerOne`,
    },
];*/

const content = contentResolver({
    project: 'desaLocum',
    brandColor: '--desa-locum-case-study',
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.auctionMobility',
            Icon: HomeTrendUpIcon,
        },
        {
            title: 'scopeOfWork.nodeJs',
            Icon: NodeJsIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
    ],
    projectInfo: {
        count: 3,
    },
    paragraphsCounters: {
        header: 1,
        about: 1,
        challenge: 1,
        mainGoals: 1,
        solutions: 1,
        summary: 2,
    },
    caseStudies: ['cbreZone', 'turo', 'objekto'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    // faqTitle: 'faq-section.title',
    // faqData: FAQ_ITEMS,
});

export const useDesaLocum = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/desa-locum/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
